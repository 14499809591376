export const en = {
  hero: {
    title: "Your Company Knowledge Operating System",
    subtitle: "Ctrl AI automatically transforms your business knowledge into an AI-optimized system that enhances every AI interaction across your organization.",
    features: [
      "Automatic knowledge extraction from company assets in 15 minutes",
      "AI-optimized knowledge graph for your entire organization",
      "Transform every AI interaction with your business context"
    ]
  },

  problem: {
    category: "The Challenge",
    title: "Business Knowledge is Trapped and Underutilized",
    subtitle: "SMBs struggle to leverage AI effectively without proper knowledge infrastructure",
    points: [
      {
        headline: "Scattered Knowledge",
        text: "Your company's valuable knowledge is trapped in documents, websites, and employee minds—making it inaccessible to AI tools."
      },
      {
        headline: "Manual Setup Burden",
        text: "Creating AI systems that understand your business requires weeks of setup and continuous maintenance."
      },
      {
        headline: "Generic AI Responses",
        text: "Without your specific business context, AI tools deliver generic responses that miss your company's voice, products, and processes."
      }
    ]
  },

  solution: {
    title: "Ctrl AI: Your Business Knowledge, Automatically Organized",
    subtitle: "Transform how your small business uses AI with our Knowledge Operating System",
    benefits: [
      {
        headline: "For Marketing Teams",
        text: "AI that instantly crafts on-brand content, understands product positioning, and targets the right customer segments"
      },
      {
        headline: "For Sales Teams",
        text: "Generate personalized proposals that highlight key differentiators, address specific pain points, and counter competitor strengths"
      },
      {
        headline: "For Support Teams",
        text: "Deliver accurate, company-specific responses that align with support processes and include detailed product information"
      }
    ],
    conclusion: "Take control of your business AI experience with a system that automatically understands your company's unique context."
  },

  features: {
    title: "Why Ctrl AI is Different",
    points: [
      {
        headline: "Automated Knowledge Extraction",
        text: "AI-powered system that automatically extracts structured knowledge from your company assets"
      },
      {
        headline: "15-Minute Onboarding",
        text: "Connect your business systems and see immediate value without weeks of setup time"
      },
      {
        headline: "Dynamic Knowledge Graph",
        text: "Your business knowledge organized into a comprehensive graph that evolves with every interaction"
      },
      {
        headline: "Continuous Improvement",
        text: "System that learns and refines your business knowledge through normal AI interactions"
      }
    ]
  },

  howItWorks: {
    title: "How Ctrl AI Works",
    points: [
      {
        title: "1. Initial Input (5 minutes)",
        bullets: [
          "Company name, website URL, and business details",
          "Upload key documents and connect business systems (Google Drive, Microsoft 365, Notion, etc.)"
        ]
      },
      {
        title: "2. Automated Extraction (5-10 minutes)",
        bullets: [
          "Intelligent crawling of company website and document parsing",
          "AI-powered structured knowledge extraction organized into your business graph"
        ]
      },
      {
        title: "3. Start Using Enhanced AI (Immediately)",
        bullets: [
          "Every connected AI tool instantly understands your company context",
          "System continuously refines and expands your knowledge through usage"
        ]
      }
    ]
  },

  smb: {
    title: "Built for Teams and Small Businesses",
    subtitle: "Unlock the full potential of AI across your organization",
    features: [
      {
        headline: "Collective Team Knowledge",
        text: "Your company's institutional knowledge, accessible to every AI tool your team uses"
      },
      {
        headline: "Industry-Specific Templates",
        text: "Pre-built knowledge structures for marketing, legal, healthcare, and more"
      },
      {
        headline: "No IT Department Required",
        text: "Set up in minutes with our guided onboarding—no technical expertise needed"
      },
      {
        headline: "Measurable ROI",
        text: "See exactly how much time your team saves with our analytics dashboard"
      }
    ]
  },

  developers: {
    title: "The Business Knowledge Platform for Builders",
    subtitle: "Build AI applications that leverage rich business context without starting from scratch",
    features: [
      {
        headline: "Simple Integration",
        text: "Our SDKs make integration a breeze—most apps connect in under an hour"
      },
      {
        headline: "Pre-Loaded Business Context",
        text: "Create AI apps that instantly understand business users' needs and requirements"
      },
      {
        headline: "Join Our AI Marketplace",
        text: "List your AI tool in our growing app store and reach business-focused users"
      },
      {
        headline: "Open API Access",
        text: "Leverage our structured business knowledge graph through standardized API endpoints"
      }
    ]
  },

  marketplace: {
    title: "Coming Soon: The Ctrl AI App Marketplace",
    subtitle: "Discover business AI tools that leverage your company knowledge",
    features: [
      {
        headline: "Business-Optimized AI Tools",
        text: "Find the perfect AI tools for your workflow—all enhanced with your company knowledge"
      },
      {
        headline: "Industry Solutions",
        text: "Specialized AI app bundles for legal, marketing, healthcare, and more"
      },
      {
        headline: "One-Click Knowledge Access",
        text: "Grant new apps precisely the knowledge access they need—nothing more, nothing less"
      }
    ],
    cta: "Join the waitlist to be first to explore the marketplace"
  },

  testimonials: {
    title: "SMBs Love Ctrl AI",
    quotes: [
      {
        text: "We were spending hours teaching every AI tool about our products and processes. Now they just know our business from day one.",
        author: "Sarah K., Marketing Director"
      },
      {
        text: "Our sales team generates proposals that actually sound like us and highlight our strengths. Implementation took minutes, not weeks.",
        author: "Marcus T., Sales Manager at Quantum SMB"
      },
      {
        text: "The automated knowledge extraction saved us months of setup time. Now our customer support is faster and more accurate.",
        author: "Jamie L., Support Team Lead"
      }
    ]
  },

  cta: {
    title: "Ready for AI That Actually Knows Your Business?",
    subtitle: "Join our waitlist and we'll send you an invite when we launch",
    emailPlaceholder: "Enter your email",
    buttons: {
      primary: "Join the Waitlist",
      secondary: "Request Demo"
    },
    disclaimer: "Early access to our knowledge operating system, app marketplace, and exclusive launch events. No spam, ever."
  },
  
  footer: {
    company: "Company",
    about: "About Us",
    careers: "Careers",
    blog: "Blog",
    legal: "Legal",
    privacy: "Privacy Policy",
    terms: "Terms of Service",
    connect: "Connect",
    twitter: "Twitter",
    linkedin: "LinkedIn",
    discord: "Join our Discord",
    rights: "© 2025 Ctrl AI. All rights reserved"
  }
};