import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../lib/utils';
import { ThemeToggle } from './ThemeToggle';

export function HeaderNavigation() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  // Memoize handlers
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setIsScrolled(currentScrollPos > 10);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  useEffect(() => {
    // Throttle scroll event
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, [handleScroll]);

  return (
    <header 
      style={{
        backdropFilter: isScrolled ? 'blur(20px)' : 'none',
        WebkitBackdropFilter: isScrolled ? 'blur(20px)' : 'none'
      }}
      className={cn(
        'fixed top-0 left-0 right-0 z-50 transition-all duration-300',
        isScrolled 
          ? 'bg-white/60 dark:bg-gray-950/60 h-[60px] shadow-sm' 
          : 'bg-transparent h-[80px]',
        isVisible 
          ? 'translate-y-0' 
          : '-translate-y-full'
      )}
    >
      <div className="container mx-auto h-full px-8 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <div 
            className="logo bg-contain bg-no-repeat bg-center h-12 w-24"
            aria-label="Ctrl AI Logo" 
          />
        </Link>
        
        {/* Only keep theme toggle */}
        <div className="flex items-center space-x-4">
          <ThemeToggle />
        </div>
      </div>
    </header>
  );
}
