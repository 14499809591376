import './index.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeProvider'
import { AuthProvider } from '@/context/AuthProvider';
import { RootLayout } from './components/RootLayout';
import { routes } from './routes';
import { useUserStore } from '@/stores/useUserStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Toaster } from 'sonner';

// Define types for routes
interface RouteChild {
  path: string;
  element: JSX.Element;
}

interface AppRoute {
  path: string;
  element: JSX.Element;
  children?: RouteChild[];
}

function App() {
  const { i18n } = useTranslation();
  const initialize = useUserStore(state => state.initialize);
  
  useEffect(() => {
    initialize();
    
    const detectUserLanguage = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const userCountry = data.country_code;
        
        if (userCountry === 'US') {
          i18n.changeLanguage('en-US');
        } else if (userCountry === 'GB') {
          i18n.changeLanguage('en-GB');
        }
      } catch (error) {
        console.error('Error detecting location:', error);
      }
    };

    if (!localStorage.getItem('i18nextLng')) {
      detectUserLanguage();
    }
  }, [initialize, i18n]);

  return (
    <Router>
      <AuthProvider>
        <ThemeProvider defaultTheme="dark">
          <RootLayout>
            <Toaster 
              position="top-center"
              toastOptions={{
                style: {
                  background: 'var(--background)',
                  color: 'var(--foreground)',
                  border: '1px solid var(--border)',
                },
                className: 'dark:bg-gray-800 dark:text-white',
              }}
            />
            <Routes>
              {(routes as AppRoute[]).map((route, i) => {
                if (route.children) {
                  return (
                    <Route key={i} path={route.path} element={route.element}>
                      {route.children.map((childRoute, j) => (
                        <Route
                          key={`${i}-${j}`}
                          path={childRoute.path}
                          element={childRoute.element}
                        />
                      ))}
                    </Route>
                  );
                }
                return (
                  <Route key={i} path={route.path} element={route.element} />
                );
              })}
            </Routes>
          </RootLayout>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;