import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from './translations/en';
const options: InitOptions = {
  resources: {
    'en-US': {
      translation: en,
    },
    'en-GB': {
      translation: en,
    },

  },
  fallbackLng: {
    'en-GB': ['en-US'],
    'en-US': ['en-GB'],
    default: ['en-US']
  },
  load: 'languageOnly',
  detection: {
    order: ['querystring', 'path', 'navigator', 'htmlTag', 'localStorage'],
    lookupQuerystring: 'lang',
    lookupFromPathIndex: 0,
    caches: ['localStorage'],
    cookieMinutes: 10080,
  },
  interpolation: {
    escapeValue: false,
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);

export default i18next; 