import { lazy, Suspense } from 'react';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { Navigate, Outlet } from 'react-router-dom';
import { ScrollToTop } from '@/components/ScrollToTop';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { AdminRoute } from '@/components/AdminRoute';
import { ErrorBoundary } from '@/components/ErrorBoundary';


// Lazy load pages
const HomePage = lazy(() => import('@/pages/HomePage'));
const PrivacyPolicy = lazy(() => import('@/pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('@/pages/TermsOfService'));
const CookiePolicy = lazy(() => import('@/pages/CookiePolicy'));

const withSuspense = (Component: React.ComponentType) => (
  <ErrorBoundary>
    <Suspense fallback={<LoadingSpinner />}>
      <ScrollToTop />
      <Component />
    </Suspense>
  </ErrorBoundary>
);

export const routes = [
  // Public routes (accessible to all) - NO protection wrapper
  {
    path: '/',
    element: withSuspense(HomePage)
  },
  
  {
    path: '/privacy',
    element: withSuspense(PrivacyPolicy)
  },
  {
    path: '/terms',
    element: withSuspense(TermsOfService)
  },
  {
    path: '/cookies',
    element: withSuspense(CookiePolicy)
  },

 

  

  // Catch-all route
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];